import React, { useState, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { auth, db } from '../firebase';

const defaultFirebaseContext = {
    user: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const [tech, setTech] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const collectionPrefix = useMemo(() => (user ? `/regions/${user.claims.regionId}` : null), [user]);
    const jobsRef = useMemo(() => (collectionPrefix ? db.collection(`${collectionPrefix}/jobs`) : null), [
        collectionPrefix,
    ]);
    const techsRef = useMemo(() => (collectionPrefix ? db.collection(`${collectionPrefix}/techs`) : null), [
        collectionPrefix,
    ]);
    const shopsRef = useMemo(() => (collectionPrefix ? db.collection(`${collectionPrefix}/shops`) : null), [
        collectionPrefix,
    ]);

    useEffect(() => {
        return auth.onAuthStateChanged(async (u) => {
            if (u) {
                const idTokenResult = await u.getIdTokenResult();
                setUser({
                    ...u,
                    claims: idTokenResult.claims,
                });
            } else {
                setUser(null);
                setIsInitialized(true);
            }
        });
    }, [setUser, setIsInitialized]);

    useEffect(() => {
        if (user?.claims?.regionId) {
            return db
                .collection(`/regions/${user.claims.regionId}/techs`)
                .doc(user.uid)
                .onSnapshot((techDoc) => {
                    if (techDoc.exists) {
                        setTech({
                            ...techDoc.data(),
                            id: techDoc.id,
                        });
                        setIsInitialized(true);
                    } else {
                        auth.signOut();
                    }
                });
        }
        auth.signOut();

        setTech(null);
        return () => {};
    }, [user, setIsInitialized]);

    const techId = useMemo(() => tech?.id, [tech]);

    return (
        <AuthContext.Provider
            value={{
                user,
                tech,
                techId,
                initialized: isInitialized,
                collectionPrefix,
                jobsRef,
                techsRef,
                shopsRef,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
