/* eslint-disable func-names */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import groupBy from 'lodash/groupBy';
import { format } from 'date-fns';

import WorkOrderComponent from './WorkOrderComponent';
import AcceptedJobModal from './JobDetails/AcceptedJobModal';
import RejectedJobModal from './JobDetails/RejectedJobModal';
import CreateJobModal from './CreateJobModal';
import LoaderComponent from '../../Shared/Loader';
import { AuthContext } from '../../Providers/UserProvider';

const OrdersPage = () => {
    const { techId, tech, jobsRef } = useContext(AuthContext);

    const [jobs, setJobs] = useState<any>([]);

    const [rejectedJobDetails, setRejectedJobDetails] = useState<any>([]);

    const [openTab, setOpenTab] = React.useState(1);

    const [showLoader, setShowLoader] = useState(false);

    const [filterTerm, setFilterTerm] = useState<string>();

    useEffect(() => {
        setShowLoader(true);
        return jobsRef.where('assignee', '==', techId).onSnapshot((querySnapshot) => {
            const arrayJobs: any[] = [];
            querySnapshot.forEach((documentSnapshot) => {
                let newJobs = documentSnapshot.data();
                newJobs = { ...newJobs, id: documentSnapshot.id };
                arrayJobs.push(newJobs);
            });
            arrayJobs.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB.getTime() - dateA.getTime();
            });
            setShowLoader(false);
            setJobs(arrayJobs);
        });
    }, [techId, jobsRef]);

    const [visibleModalType, setVisibleModalType] = useState('');
    const [activeDetailsId, setActiveDetailsId] = useState('');

    const [createJobModalVisibility, setCreateJobModalVisibility] = useState(false);

    const [sortingOrder, setSortingOrder] = useState('');

    const sortJobs = () => {
        if (sortingOrder === 'descending') {
            setSortingOrder('ascending');
        } else {
            setSortingOrder('descending');
        }
    };

    const filterdJobs = jobs.filter((job) => JSON.stringify(job.date).includes(filterTerm || ''));

    const sortedJobs = useMemo(() => {
        const jobsByDate = [...filterdJobs];
        jobsByDate.sort(function (a, b) {
            return (sortingOrder === 'descending' ? -1 : 1) * (new Date(b.date).getTime() - new Date(a.date).getTime());
        });
        return jobsByDate;
    }, [filterdJobs, sortingOrder]);

    const rejectedJobs = useMemo(() => tech?.rejectedJobs || [], [tech]);

    const activeJobs = useMemo(() => sortedJobs.filter((job) => job.status === 'active'), [sortedJobs]);

    const pendingJobs = useMemo(() => sortedJobs.filter((job) => job.status === 'pending'), [sortedJobs]);

    const completeAndCancelledJobs = useMemo(
        () => sortedJobs.filter((job) => job.status === 'complete' || job.status === 'cancelled'),
        [sortedJobs],
    );

    const sortedJobsByDate = useMemo(() => groupBy(sortedJobs, (job) => format(new Date(job.date), 'MM/dd/yyyy')), [
        sortedJobs,
    ]);

    const pendingJobsByDate = useMemo(() => groupBy(pendingJobs, (job) => format(new Date(job.date), 'MM/dd/yyyy')), [
        pendingJobs,
    ]);

    const activeJobsByDate = useMemo(() => groupBy(activeJobs, (job) => format(new Date(job.date), 'MM/dd/yyyy')), [
        activeJobs,
    ]);

    const completeJobsByDate = useMemo(
        () => groupBy(completeAndCancelledJobs, (job) => format(new Date(job.date), 'MM/dd/yyyy')),
        [completeAndCancelledJobs],
    );

    const completeJobs = useMemo(() => sortedJobs.filter((job) => job.status === 'complete'), [sortedJobs]);
    const activeJobsLength = useMemo(() => activeJobs.length, [activeJobs]);
    const completeJobsLength = useMemo(() => completeJobs.length, [completeJobs]);

    const closeWorkDetailsModal = () => {
        setVisibleModalType('');
        setActiveDetailsId('');
        setRejectedJobDetails(null);
    };

    const goToAcceptedDetails = (jobDetailsId) => {
        setVisibleModalType('acceptedJob');
        setActiveDetailsId(jobDetailsId);
    };

    const goToRejectedDetails = (jobDetails) => {
        setVisibleModalType('rejectedJob');
        setRejectedJobDetails(jobDetails);
    };

    const openCreateJobModal = () => {
        setCreateJobModalVisibility(true);
    };

    const closeCreateJobModal = () => {
        setCreateJobModalVisibility(false);
    };

    return (
        <div>
            <LoaderComponent showLoader={showLoader} />
            <div className="bg-white-600 py-4 text-center shadow-lg">
                <div className="my-auto pl-2.5 pr-2.5">
                    <p className="text-2xl text-gray-800 font-semibold">Your current jobs</p>
                    {pendingJobs.length > 0 ? (
                        <p className="text-lg text-gray-800">Showing {pendingJobs.length} pending job(s)</p>
                    ) : (
                        <p className="text-lg text-gray-800">You have no pending jobs</p>
                    )}
                </div>
            </div>
            <button
                disabled={tech.status === 'unavailable'}
                type="button"
                className={
                    tech.status === 'unavailable'
                        ? 'bg-gray-200 text-gray-400 font-bold py-2 px-4 w-72 w-full'
                        : 'bg-green-400 hover:bg-green-900 text-white font-bold py-2 px-4 w-72 w-full'
                }
                onClick={() => openCreateJobModal()}
            >
                CREATE JOB
            </button>

            <div className="flex flex-wrap pl-2.5 pr-2.5 mt-2 mb-8">
                <div className="w-full">
                    <ul className=" mb-0 list-none pt-3 pb-4 grid grid-cols-3 gap-4" role="tablist">
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                            <button
                                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                                    openTab === 1 ? 'text-white bg-primaryTheme' : 'text-blue-600 bg-white'
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                type="button"
                            >
                                Pending
                            </button>
                            {pendingJobs.length > 0 ? (
                                <div className="object-right-top top-0 right-0 absolute bg-yellow-400 -m-3	rounded-full h-8 w-8 flex items-center justify-center text-white z-50">
                                    {pendingJobs.length}
                                </div>
                            ) : null}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                            <button
                                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                                    openTab === 2 ? 'text-white bg-primaryTheme' : 'text-blue-600 bg-white'
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                type="button"
                            >
                                Active
                            </button>
                            {activeJobsLength > 0 ? (
                                <div className="object-right-top top-0 right-0 absolute bg-green-400 -m-3 rounded-full h-8 w-8 flex items-center justify-center text-white z-50">
                                    {activeJobsLength}
                                </div>
                            ) : null}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center relative">
                            <button
                                className={`text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal w-full ${
                                    openTab === 3 ? 'text-white bg-primaryTheme' : 'text-blue-600 bg-white'
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(3);
                                }}
                                type="button"
                            >
                                Complete
                            </button>
                            {completeJobsLength > 0 ? (
                                <div className="object-right-top top-0 right-0 absolute bg-primaryTheme -m-3	rounded-full h-8 w-8 flex items-center justify-center text-white z-50">
                                    {completeJobsLength}
                                </div>
                            ) : null}
                        </li>
                    </ul>
                    <div className="text-center">
                        <div className="shadow flex mt-1">
                            <select
                                required
                                className="p-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-full"
                                onChange={(event) => setFilterTerm(format(new Date(event.target.value), 'yyyy-MM-dd'))}
                                value={filterTerm}
                            >
                                <option className="placeholder-gray-600" value={' '}>
                                    Select Date {filterTerm}
                                </option>
                                {Object.entries(sortedJobsByDate).map(([date]) => {
                                    return (
                                        <option key={date} value={date}>
                                            {date}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                type="button"
                                onClick={() => {
                                    setFilterTerm('');
                                }}
                                className="w-auto flex justify-end items-center p-2 bg-red-600"
                            >
                                <FontAwesomeIcon className="text-2xl text-white" icon={faTrash} />
                            </button>
                            <button
                                type="button"
                                className="text-white font-bold p-2 capitalize flex flex-row bg-blue-500"
                                onClick={() => {
                                    sortJobs();
                                }}
                            >
                                Sort{' '}
                                {sortingOrder === 'ascending' ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 d-inline-block"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M16 17l-4 4m0 0l-4-4m4 4V3"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M8 7l4-4m0 0l4 4m-4-4v18"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                        <div className="px-1 pb-5 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? 'block' : 'hidden'} id="pending">
                                    {Object.entries(pendingJobsByDate).map(([date, datedJobs]) => {
                                        return (
                                            <div key={date}>
                                                <h2 className="mt-3 pl-1"> {date}</h2>
                                                {datedJobs.map((job) => (
                                                    <WorkOrderComponent
                                                        job={job}
                                                        key={job.id}
                                                        goToDetails={goToAcceptedDetails}
                                                    />
                                                ))}
                                            </div>
                                        );
                                    })}
                                    {rejectedJobs.map((job) => (
                                        <>
                                            <h2 className="mt-3 pl-1"> Rejected</h2>
                                            <WorkOrderComponent
                                                job={job}
                                                key={job.id}
                                                goToDetails={() => goToRejectedDetails(job)}
                                            />
                                        </>
                                    ))}
                                </div>
                                <div className={openTab === 2 ? 'block' : 'hidden'} id="active">
                                    {Object.entries(activeJobsByDate).map(([date, datedJobs]) => {
                                        return (
                                            <div key={date}>
                                                <h2 className="mt-3 pl-1"> {date}</h2>
                                                {datedJobs.map((job) => (
                                                    <WorkOrderComponent
                                                        job={job}
                                                        key={job.id}
                                                        goToDetails={goToAcceptedDetails}
                                                    />
                                                ))}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={openTab === 3 ? 'block' : 'hidden'} id="complete">
                                    {Object.entries(completeJobsByDate).map(([date, datedJobs]) => {
                                        return (
                                            <div key={date}>
                                                <h2 className="mt-3 pl-1"> {date}</h2>
                                                {datedJobs.map((job) => (
                                                    <WorkOrderComponent
                                                        job={job}
                                                        key={job.id}
                                                        goToDetails={goToAcceptedDetails}
                                                    />
                                                ))}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {visibleModalType === 'acceptedJob' ? (
                <>
                    <AcceptedJobModal onClose={closeWorkDetailsModal} jobDetailsId={activeDetailsId} />
                </>
            ) : null}
            {visibleModalType === 'rejectedJob' ? (
                <>
                    <RejectedJobModal onClose={closeWorkDetailsModal} rejectedJob={rejectedJobDetails} />
                </>
            ) : null}
            {createJobModalVisibility && (
                <>
                    <CreateJobModal onClose={closeCreateJobModal} />
                </>
            )}
        </div>
    );
};

export default OrdersPage;
