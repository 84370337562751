import React, { useContext, useState, useEffect, useMemo } from 'react';
import { auth, db } from '../../firebase';
import { AuthContext } from '../../Providers/UserProvider';

const SettingsPage = () => {
    const { tech, user }: any = useContext(AuthContext);
    const [techRegion, setTechRegion] = useState('');
    const [availableRegions, setAvailableRegions] = useState<Array<{ name: string; center: any; id: string }>>([]);

    // needs to be the available regions for the tech
    useEffect(() => {
        return db.collection(`/regions`).onSnapshot((regionSnapshot) => {
            setAvailableRegions(regionSnapshot.docs.map((doc) => ({ ...(doc.data() as any), id: doc.id })));
        });
    }, [setAvailableRegions]);

    const regionId = useMemo(() => (user ? user?.claims?.regionId : null), [user]);

    useEffect(
        () =>
            db
                .collection('/regions')
                .doc(regionId)
                .onSnapshot((regionDoc) => {
                    const data = regionDoc.data();
                    setTechRegion(data?.name);
                }),
        [regionId],
    );

    // needs to change user claim regionId
    const onChangeHandler = (setFunc: (value: any) => void) => (evt) => setFunc(evt.currentTarget.value);

    return (
        <div>
            <div className="bg-white-600 py-4 text-center shadow-lg">
                <div className="my-auto">
                    <p className="text-2xl text-gray-800 font-semibold">Settings</p>
                    <p className="text-lg text-gray-800">Welcome {tech.name}</p>
                </div>
            </div>
            <div className="flex flex-col p-8">
                <div className="mt-4 text-base text-gray-600 text-left">
                    <p className="my-1">Name: {tech.name}</p>
                    <p className="my-1">Email: {tech.email}</p>
                    <p className="my-1">Phone: {tech.phone}</p>
                </div>
                <div className="mt-4">
                    <p className="mr-3 text-gray-800 mb-3">You are currently set to: {techRegion}</p>
                    <select
                        id="regionSelect"
                        className="px-3 py-3 placeholder-gray-600 text-gray-800 bg-gray-100 rounded focus:shadow-outline focus:outline-none w-72"
                        required
                        value={techRegion}
                        onChange={onChangeHandler(setTechRegion)}
                    >
                        <option className="placeholder-gray-600">Change region</option>
                        {availableRegions.map((region) => (
                            <option key={region.id} value={region.id}>
                                {region.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mt-4 text-base text-gray-600 text-left">
                    <p className="my-1">
                        SolutionWorks is a resource management app designed for you. If you have any questions or issues
                        please email us at:{' '}
                        <a className="text-blue-400" href="mailto:example@solutionworks.com">
                            example@solutionworks.com
                        </a>
                    </p>
                </div>
                <div className="text-center mt-12">
                    <button
                        type="button"
                        className="bg-primaryTheme hover:bg-blue-900 text-white font-bold py-2 px-4 rounded w-72"
                        onClick={() => {
                            auth.signOut();
                        }}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SettingsPage;
