import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyChhKgsxrFZb-zpI9UgtEx-pUMWKFPBDxs',
    authDomain: 'techrepair-tdc.firebaseapp.com',
    projectId: 'techrepair-tdc',
    storageBucket: 'techrepair-tdc.appspot.com',
    messagingSenderId: '741915654698',
    appId: '1:741915654698:web:adeb2caf94db7889a3a153',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export { firebase };
